
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  name: "Print",
})
export default class Print extends Vue {
  private printList: Array<any> = [];
  private hasAnswerFlag: Array<any> = [];
  private printEvent(): void {
    window.print();
  }
  private delItem(index: number): void {
    this.printList.splice(index, 1);
    this.hasAnswerFlag.splice(index, 1);
  }
  mounted() {
    let _printList: any = localStorage.getItem("fangtian_printList");
    if (_printList) {
      _printList = JSON.parse(_printList);
    }
    this.printList = _printList.printList;
    this.hasAnswerFlag = _printList.hasAnswerFlag;
  }
  beforeDestroy() {
    localStorage.remove("fangtian_printList");
  }
}
